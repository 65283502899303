import React, { ReactNode, useEffect, useState } from 'react';
import { cn } from '@bits/ui/utils/cn';
import { motion } from 'framer-motion';

import { nl2br } from '../../nl2br';
import { Spinner } from './spinner';

export const LoaderOverlay = ({
  className,
  text,
}: {
  className?: string;
  text?: string | ReactNode | { text: string; delay: number }[];
}) => {
  const [currentText, setCurrentText] = useState<string | undefined>();

  useEffect(() => {
    if (typeof text === 'string') {
      setCurrentText(text);
    } else if (Array.isArray(text)) {
      let currentIndex = -1;

      const showNext = () => {
        currentIndex++;
        if (!!text?.[currentIndex]) setCurrentText(text?.[currentIndex]?.text);
        if (currentIndex < text.length - 1) {
          timeout = setTimeout(showNext, text?.[currentIndex + 1]?.delay);
        }
      };

      let timeout = setTimeout(showNext, text[0]?.delay);

      return () => clearTimeout(timeout);
    }
  }, [text]);

  return (
    <div
      className={cn(
        'absolute flex inset-0 bg-theme-canvas-backgroundColor/80 backdrop-blur-sm',
        className
      )}
      aria-live="assertive"
    >
      <motion.div layout="position" className="m-auto w-full">
        <div className="flex flex-col items-center">
          <Spinner />

          {currentText && (
            <motion.p
              key={currentText}
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              className="text-theme-typography-paragraph-textColor text-theme-typography-paragraph-fontSize mt-6 text-center"
            >
              {nl2br(currentText)}
            </motion.p>
          )}
        </div>
      </motion.div>
    </div>
  );
};
