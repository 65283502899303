import {
  availableFeatureFlags,
  useFeatureFlag,
} from './feature-flags-provider';

export const useCanUseCaseChecksOverview = () => {
  return useFeatureFlag(
    availableFeatureFlags.CAN_USE_CASE_CHECKS_OVERVIEW,
    false
  );
};
