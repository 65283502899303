const validNorwegianPINRegex = /^([0-7]\d)(0[1-9]|1[0-2])(\d{2})-?(\d{5})$/;

export const validateNorwegianPersonalIdentityNumber = (id: string) => {
  // match regex for Norwegian personal identity number
  const match = validNorwegianPINRegex.test(id);

  if (!match) {
    return false;
  }

  const d1 = parseInt(id.substring(0, 1), 10);
  const d2 = parseInt(id.substring(1, 2), 10);
  const m1 = parseInt(id.substring(2, 3), 10);
  const m2 = parseInt(id.substring(3, 4), 10);

  const y1 = parseInt(id.substring(4, 5), 10);
  const y2 = parseInt(id.substring(5, 6), 10);

  const finalFive = id.substring(6, 11);

  const i1 = parseInt(id.substring(6, 7), 10);
  const i2 = parseInt(id.substring(7, 8), 10);
  const i3 = parseInt(id.substring(8, 9), 10);
  // Extract validate digits
  let k1 =
    11 -
    ((3 * d1 +
      7 * d2 +
      6 * m1 +
      m2 +
      8 * y1 +
      9 * y2 +
      4 * i1 +
      5 * i2 +
      2 * i3) %
      11);
  let k2 =
    11 -
    ((5 * d1 +
      4 * d2 +
      3 * m1 +
      2 * m2 +
      7 * y1 +
      6 * y2 +
      5 * i1 +
      4 * i2 +
      3 * i3 +
      2 * k1) %
      11);

  if (k1 == 11) {
    k1 = 0;
  }
  if (k2 == 11) {
    k2 = 0;
  }
  if (k1 == 10 || k2 == 10) {
    return false;
  }

  return (
    parseInt(id.substring(9, 10), 10) === k1 &&
    parseInt(id.substring(10, 11), 10) === k2
  );
};
