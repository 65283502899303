import {
  EvaluationContext,
  InMemoryProvider,
  OpenFeature,
  OpenFeatureProvider,
  useFlag,
} from '@openfeature/react-sdk';

export { OpenFeatureProvider as FeatureFlagProvider };

type FeatureFlagConfig = Omit<
  NonNullable<ConstructorParameters<typeof InMemoryProvider>[0]>[string],
  'contextEvaluator'
> & {
  contextEvaluator: (context: BitsFeatureFlagContext) => unknown;
};

export interface BitsFeatureFlagContext {
  isBitsEmployee: boolean;
  environment: 'development' | 'staging' | 'sandbox' | 'production';
  email: string;
}

export enum availableFeatureFlags {
  ENABLED_BETA_WORKFLOW_STEPS = 'ENABLED_BETA_WORKFLOW_STEPS',
  CAN_USE_TOP_LEFT_LOGO_IN_THEME = 'CAN_USE_TOP_LEFT_LOGO_IN_THEME',
  CAN_USE_REMOTE_REGISTRY_DOCUMENTS = 'CAN_USE_REMOTE_REGISTRY_DOCUMENTS',
  CAN_USE_NAVIGATION_BETA_ITEMS = 'CAN_USE_NAVIGATION_BETA_ITEMS',
  CAN_USE_ADVANCED_MONITORING = 'CAN_USE_ADVANCED_MONITORING',
  CAN_USE_ENTITIES_IN_APPLICATIONS = 'CAN_USE_ENTITIES_IN_APPLICATIONS',
  CAN_USE_LEGAL_DOCUMENTS = 'CAN_USE_LEGAL_DOCUMENTS',
  CAN_USE_CASE_CHECKS_OVERVIEW = 'CAN_USE_CASE_CHECKS_OVERVIEW',
}

const flagConfig: { [key in availableFeatureFlags]: FeatureFlagConfig } = {
  [availableFeatureFlags.ENABLED_BETA_WORKFLOW_STEPS]: {
    disabled: false,
    variants: {
      off: [],
      nonProduction: ['Edit Organization Information'],
      cardboard: ['Edit Organization Information'],
      continia: ['Edit Organization Information'],
      mimo: [
        'Edit Organization Information',
        'Stakeholder individuals verification',
      ],
      qliro: ['Edit Organization Information'],
      clevercards: ['Edit Organization Information'],
    },
    defaultVariant: 'off',
    contextEvaluator: ({ email, environment }) => {
      if (environment !== 'production') {
        return 'nonProduction';
      }

      // If production
      if (typeof email === 'string') {
        if (email.endsWith('continia.com')) {
          return 'continia';
        }
        if (email.endsWith('cardboard.inc')) {
          return 'cardboard';
        }
        if (email.endsWith('mimohq.com')) {
          return 'mimo';
        }
        if (email.endsWith('qliro.com')) {
          return 'qliro';
        }
        if (email.endsWith('clevercards.com')) {
          return 'clevercards';
        }
      }
      return 'off';
    },
  },
  [availableFeatureFlags.CAN_USE_TOP_LEFT_LOGO_IN_THEME]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee, environment }) =>
      isBitsEmployee || environment !== 'production' ? 'on' : 'off',
  },
  [availableFeatureFlags.CAN_USE_REMOTE_REGISTRY_DOCUMENTS]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee }) => (isBitsEmployee ? 'on' : 'off'),
  },
  [availableFeatureFlags.CAN_USE_NAVIGATION_BETA_ITEMS]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ email }) => {
      return typeof email === 'string' &&
        ['@bits.bi', '@mimohq.com', '@qliro.com', '@clevercards.com'].some(
          (emailDomain) => email.includes(emailDomain)
        )
        ? 'on'
        : 'off';
    },
  },
  [availableFeatureFlags.CAN_USE_ADVANCED_MONITORING]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ isBitsEmployee }) => (isBitsEmployee ? 'on' : 'off'),
  },
  [availableFeatureFlags.CAN_USE_ENTITIES_IN_APPLICATIONS]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ email }) =>
      typeof email === 'string' &&
      ['@bits.bi', '@mimohq.com', '@qliro.com', '@clevercards.com'].some(
        (emailDomain) => email.includes(emailDomain)
      )
        ? 'on'
        : 'off',
  },
  [availableFeatureFlags.CAN_USE_LEGAL_DOCUMENTS]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ email }) => {
      return email && email.includes('@bits.bi') ? 'on' : 'off';
    },
  },
  [availableFeatureFlags.CAN_USE_CASE_CHECKS_OVERVIEW]: {
    disabled: false,
    variants: {
      on: true,
      off: false,
    },
    defaultVariant: 'off',
    contextEvaluator: ({ email }) => {
      return email &&
        ['bits.bi', 'mimohq.com'].some((emailDomain) =>
          email.includes(emailDomain)
        )
        ? 'on'
        : 'off';
    },
  },
};

// @ts-expect-error - Hugo: Allow our strictly typed contextEvaluator
OpenFeature.setProvider(new InMemoryProvider(flagConfig));

export const setFeatureFlagContext = (
  context: Partial<BitsFeatureFlagContext>
) =>
  OpenFeature.setContext({
    ...OpenFeature.getContext(),
    ...context,
  } as EvaluationContext);

export const useFeatureFlag = useFlag;
