import { ISO3166Alpha2 } from '../iso-3166';
import { validateDanishPersonalIdentityNumber } from './validate-danish-personal-identity-number';
import { validateFinnishPersonalIdentityNumber } from './validate-finnish-personal-identity-number';
import { validateNorwegianPersonalIdentityNumber } from './validate-norwegian-personal-identity-number';
import { validateSwedishPersonalIdentityNumber } from './validate-swedish-personal-identity-number';

const validatorMap: Partial<Record<ISO3166Alpha2, (id: string) => boolean>> = {
  SE: validateSwedishPersonalIdentityNumber,
  FI: validateFinnishPersonalIdentityNumber,
  DK: validateDanishPersonalIdentityNumber,
  NO: validateNorwegianPersonalIdentityNumber,
};

export const validatePersonalIdentityNumber = ({
  id,
  issuingCountry,
}: {
  id: string;
  issuingCountry: ISO3166Alpha2;
}) => {
  const validator = validatorMap[issuingCountry];

  if (!validator) {
    return true;
  }

  return validator(id);
};
