import React, { forwardRef, useMemo } from 'react';
import { InputProps } from 'react-day-picker';

import { cn } from '../utils/cn';

export interface ThemedInputProps extends InputProps {
  label?: string;
  error?: string | boolean;
  hint?: string;
  type: 'text' | 'password' | 'email' | 'number' | 'tel';
  optional?: string | boolean | null;
  onValueChange?: (value: string) => void;
  disabled?: boolean;
  defaultValue?: string;
  icon?: React.ReactNode;
  iconPosition?: 'left' | 'right';
}

export const ThemedInput = forwardRef<HTMLInputElement, ThemedInputProps>(
  (
    {
      label,
      hint,
      error,
      type,
      onChange,
      onValueChange,
      optional,
      icon,
      iconPosition = 'right',
      ...props
    },
    ref
  ) => {
    const uuid = useMemo(() => Math.random().toString(36).substring(7), []);

    return (
      <div className="text-left">
        <label className="relative">
          {label && (
            <p
              className={cn(
                'text-theme-inputs-labelFontSize text-theme-inputs-labelTextColor mb-2',
                error && 'text-theme-inputs-errorColor'
              )}
            >
              {label} {optional && `(${optional})`}
            </p>
          )}
          <div className="relative">
            <input
              autoComplete="off"
              type={type}
              required={!optional}
              className={cn(
                'transition-all flex w-full px-3 py-2',
                'disabled:bg-[color-mix(in_srgb,var(--inputs-textColor)_10%,var(--inputs-backgroundColor))] disabled:cursor-not-allowed disabled:opacity-75',
                'text-theme-inputs-fontSize border-theme-inputs-borderColor text-theme-inputs-textColor',
                'theme-inputs-textColor bg-theme-inputs-backgroundColor [border-width:var(--inputs-borderWidth)] [border-radius:var(--inputs-cornerRadius)]',
                'placeholder-theme-inputs-placeholderColor min-h-[44px]',
                '[box-shadow:var(--inputs-shadow)] leading-normal',
                'focus-visible:border-theme-general-focusColor focus-visible:outline-1 focus-visible:outline-theme-general-focusColor outline-offset-0 outline-none',
                error &&
                  'border-theme-inputs-errorColor focus-visible:border-theme-inputs-errorColor focus-visible:!outline-theme-inputs-errorColor text-theme-inputs-errorColor',
                iconPosition === 'left' && icon && 'pl-10'
              )}
              ref={ref}
              onChange={(e) => {
                onChange?.(e);
                onValueChange?.(e.target.value);
              }}
              // Accessible error handling
              aria-invalid={!!error}
              aria-describedby={
                hint && !error
                  ? uuid + 'hint'
                  : error
                    ? uuid + 'error'
                    : undefined
              }
              {...props}
            />
            {icon && (
              <div
                className={cn(
                  'pointer-events-none absolute right-3 top-1/2 -translate-y-1/2 opacity-50',
                  iconPosition === 'right' && 'right-3',
                  iconPosition === 'left' && 'left-3'
                )}
              >
                {icon}
              </div>
            )}
          </div>
        </label>
        {hint && (
          <p
            id={uuid + 'hint'}
            className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-hintTextColor mt-1"
          >
            {hint}
          </p>
        )}
        {error && typeof error === 'string' && (
          <p
            id={uuid + 'error'}
            className="text-theme-inputs-hintAndErrorFontSize text-theme-inputs-errorColor mt-1"
          >
            {error}
          </p>
        )}
      </div>
    );
  }
);

ThemedInput.displayName = 'ThemedInput';
