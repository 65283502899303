import { bitsI18nNextPlugin } from '@bits/i18n';
import i18n from 'i18next';
import I18NextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import common from '../public/locales/en/common.json';
import enums from '../public/locales/en/enums.json';
import errors from '../public/locales/en/errors.json';
import login from '../public/locales/en/login.json';
import monitoring from '../public/locales/en/monitoring.json';
import onboarding from '../public/locales/en/onboarding.json';
import reports from '../public/locales/en/reports.json';
import risk from '../public/locales/en/risk.json';
import workflows from '../public/locales/en/workflows.json';

export const resources = {
  en: {
    common,
    login,
    onboarding,
    reports,
    monitoring,
    workflows,
    risk,
    errors,
    enums,
  },
} as const;

export const localLazyResources = [
  'questionnaire-builder',
  'monitoring-value',
  'governance-types',
  'beneficial-owner-control-types',
  'edit-org-info',
] as const;

export const apiResources = [
  'shared',
  'kyb-questionnaire',
  'kyc-questionnaire',
  'sanction-lists',
  'adverse-media-categories',
  'pep-classes',
] as const;

export type TranslationKeys = (typeof resources)['en'] & {
  // Local lazy namespaces
  [key in (typeof localLazyResources)[number]]: Record<string, string>;
} & {
  // API namespaces
  [key in (typeof apiResources)[number]]: Record<string, string>;
};

i18n
  .use(initReactI18next)
  .use(I18NextHttpBackend)
  .init({
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'fi', 'sv', 'da', 'no'],
    partialBundledLanguages: true,
    resources,
    react: {
      useSuspense: false,
    },
    defaultNS: 'common',
    ns: [
      // Local namespaces
      'common',
      'workflows',
      'onboarding',
      'reports',
      'login',
      'monitoring',
      'risk',
      'errors',
      'enums',
    ],
    backend: bitsI18nNextPlugin({
      apiNamespaces: apiResources,
      localLazyNamespaces: localLazyResources,
    }),
    interpolation: {
      escapeValue: false,
    },
  })
  .then();

export default i18n;
